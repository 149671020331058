import React, {useCallback, useMemo} from 'react';
import { Page, LegacyCard, LegacyStack, Layout, Button, Text } from "@shopify/polaris";
import {navigate} from 'gatsby';
import CustomizationTab from './customizationTab';
import CreateOfferSteps from '../../createOfferSteps';
import PlusLogo from '../../../../../images/PlusLogo.png';
import './checkoutExtensionGuide.css';
import styled from 'styled-components';
import Onboarding from '../../../../../components/onBoarding';

const PageWrapper = styled.div`
  .Polaris-Page {
    max-width: 120rem;
  }
`

const CheckoutExtensionGuide = (props) => {
  const {location} = props;
  const rules = location && location.state && location.state.rules;
  const goToCustomization = useCallback(() => {
    navigate("/app/customizations", {
      state: location.state,
      replace: true,
    })
  }, [rules]);

  
  return (
    <PageWrapper>
    <Page backAction={{
                content: 'Customizations',
                onAction: goToCustomization}} 
                title="Create new">
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              {/*
              <CreateOfferSteps step={1}/>
              */}
              <LegacyStack distribution='center'>
                <div className='Guide-wrapper'>
                  <div>
                  <LegacyStack distribution='center'>
                    <Text variant="headingSm" as="h3">
                      <Text  variant="bodyMd" as="span" tone="subdued">Create a customization</Text>
                    </Text>
                  </LegacyStack>
                  <Onboarding rules={rules} customization/>
                  </div>
                </div>
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
    </PageWrapper>
  );
};

export default CheckoutExtensionGuide;